.hide-chart-caption g[class$='-caption'] {
  display: none;
}

/* 
  As of April '22 on MacOS Chrome
  Explanation here: https://stackoverflow.com/questions/37793086/why-is-the-font-style-bold-not-correctly-applied-in-chrome-on-this-website
*/
* {
  -webkit-font-smoothing: antialiased;
}

.ant-table-wrapper .ant-spin-blur {
  opacity: 0.25;
}

.saved-segments-submenu {
  max-width: 300px;
}
